import React from 'react'
import { FaBars, FaFacebook, FaInstagram } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    HeroLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavRedes,
    NavRedesLink
} from './NavbarElements';
import image from '../../images/logo.png';



const toggleHome = () => {
    scroll.scrollToTop();
}

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
            <NavLogo 
                to="/" onClick={toggleHome}
            >
                <HeroLogo src={image}/>
            </NavLogo>
            <MobileIcon onClick={toggle}>
                <FaBars />
            </MobileIcon>
            <NavMenu>
                <NavItem>
                    <NavLinks 
                        to="varandas"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact= 'true'
                        offset={-80}

                    >Varandas</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="ambiente"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    
                    >Ambiente</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="culinaria"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    
                    >Culinária</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="fotos"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    
                    >Fotos</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="cardapio"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    
                    >Cardápio</NavLinks>
                </NavItem>
            </NavMenu>

            <NavRedes>
            <NavRedesLink target="blank" to="https://www.facebook.com/varandasbonitoms/"><FaFacebook /></NavRedesLink>
                <NavRedesLink target="blank" to="https://www.instagram.com/varandasbonitoms"><FaInstagram /></NavRedesLink>
            </NavRedes>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar;
