import React, {useState, useRef, useEffect} from 'react'
import Video from '../../videos/hero_alpha_fachada.MOV';
import Background from '../../images/hero_background.jpg';
import { motion, useInView, useAnimation } from "framer-motion";


import { 
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroImg,
    HeroContent,
    HeroH1,
    HeroBtnWrapper,
    ArrowRight,
    ArrowForward,
} from './HeroElements';

import { Button } from '../ButtonElements';


const HeroSection = () => {
    const [hover, setHover] = useState(false);
    
    const onHover = () => {
        setHover(!hover);
    }
    
    const ref = useRef(null);
    const mainControls = useAnimation();

    const isInView = useInView(ref, {once: true});


    useEffect(() => {
        if(isInView){
            mainControls.start("visible");
        }
    }, [isInView]);

  return (
    <HeroContainer className='topPage'>      
        <HeroBg>
            <VideoBg autoPlay loop muted playsInline suspend src={Video} type="video/mp4" />
            <HeroImg src={Background} />
        </HeroBg>        
        <HeroContent ref={ref}>
        <motion.div 
            variants={{
                hidden: { opacity: 0, y: 75, overflowY: 'hideen' },
                visible: { opacity: 1, y: 0 }
            }}
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
            }}
            initial= "hidden"
            animate= {mainControls}
            transition={{ duration: .9, delay: .7 }}
        >
            <HeroH1>A Varanda do Coração de Bonito</HeroH1>
            <HeroBtnWrapper>
                <Button 
                    to="varandas"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact= 'true'
                    offset={-50}

                onMouseEnter={onHover}
                onMouseLeave={onHover}>
                    CONHECER {hover ? <ArrowForward /> : <ArrowRight />}</Button>
            </HeroBtnWrapper>

        </motion.div>
        </HeroContent>

        
      
    </HeroContainer>
  )
}

export default HeroSection;
