import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';



export const FooterDiv = styled.div`
    background: #FF9746;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`
export const InfosFooter = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;

    @media screen and (max-width: 768px) {
        align-items: center;
    }
`
export const InfoRedes = styled.div``
export const FooterLinks = styled(LinkR)`
    color: white;
    font-size: 2.4rem;
    margin-right: 10px;

    &:hover {
        color: #060606
    } ;
`
export const InfoP = styled.p`
    color: white;


    @media screen and (max-width: 768px) {
        text-align: center;
    }
`
export const MapFooter = styled(LinkR)`
`
export const MapImg = styled.img`
border: solid 10px #FF9746;
cursor: pointer;

&:hover {
    border: solid 10px #FCC089;
}


@media screen and (max-width: 768px) {
    width: 100%;
}

`
