import styled from "styled-components";
// import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const NavMenu = styled.ul`
    display: flex;
    position: ${({ active }) => (active ? 'fixed' : 'sticky')};
    width: 100%;
    top: ${({ active }) => (active ? '0' : 'auto')};
    overflow-x: ${({ active }) => (active ? 'scroll' : 'scroll')};
    margin-left: 30px;
    background: ${({ active }) => (active ? '#FFF' : 'transparent')};
    padding: ${({ active }) => (active ? '15px 110px 15px 15px' : '0 110px 0 0')};

    &::-webkit-scrollbar { 
        display: none;
    }   
    z-index: 20001;
`

export const NavItem = styled.li`
    display: flex;
    margin-right: 30px;
    min-width: 180px;
    text-align: center;
    justify-content: center;
    cursor: pointer;

    // &:hover {
    //     transition: all 0.2s ease-in-out;
    //     transform: scale(1.1);
    // }
`
export const NavLinks = styled(LinkS)`
    background: #E38339;
    min-width: 200px;
    padding: 10px 10px;
    cursor: pointer;
    color: #FDDFC7;
    text-transform: uppercase;
    border: solid 5px #E38339;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #964F39;
        border: solid 5px #FDDFC7;
    }

    &.active {
        transition: all 0.2s ease-in-out;
        background: #964F39;
        border: solid 5px #FDDFC7;
    }

    

`