import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom';
import backgroundImg from '../../../images/background_cardapio.jpg'



export const IntroCardapioWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 450px;
    padding: 80px 0 50px 0;
    background-image: url(${backgroundImg});
    background-size: cover;
    justify-content: center;
    align-items: center;
    margin-bottom: -35px;
`

export const WrapperMenu = styled.div`
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
`
    
    export const BackHome = styled(LinkR)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
    background: #E38339;
    font-size: 2.2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border-radius: 50%;
    margin: 5px;

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        color: #964F39;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.2rem;
    }
`

export const WrapperImg = styled.div`
    display: flex;
    width: 50%;
    height: 270px;
    flex-direction: column;
    justify-content: center;

    margin-right: -22px;
    background: #FFCBA3;
    background: rgba(255, 195, 152, 0.8);
    border-radius: 20px;
    border: solid 2px #FF9746;
`

export const TitleCard = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    text-transform: uppercase;
    background: #FFB275;
    width: 100%;
    height: 25%;
    margin: 0;  
    color: white;
    text-aling: center;
    border-bottom: solid 2px #FF9746;

    @media screen and (max-width: 700px){
        font-size: 28px;
    }

`
export const WrapperInfosCard = styled.div`
    display: flex;
    width: 100%;
    height: 75%;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;

`

export const Varandas = styled.img`
    width: 25%;
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 700px) {
        width: 60%;
    }
`

export const TextCard = styled.p`
    text-align: center;

`
    export const Text01 = styled.p`
    color: white;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    
    @media screen and (max-width: 700px) {
        font-size: 13px;
    }
    
    `
    export const Text02 = styled.p`
    color: white;
    font-size: 20px;
    font-weight: bold;
    @media screen and (max-width: 700px) {
        font-size: 15px;
    }
`
    
export const NavRedes = styled.nav`
    display: flex;
    aling-items: center;
`


export const NavRedesLink = styled(LinkR)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #FFF;
    font-size: 2.2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    background: #E38339;
    border-radius: 50%;
    margin: 5px;

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
        color: #964F39;
        }

        @media screen and (max-width: 500px) {
        font-size: 1.2rem;
    }
`
