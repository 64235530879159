import React, { useEffect, useState } from 'react'

import {
    NavMenu,
    NavItem,
    NavLinks

} from './navMenuCardapioElements';

function NavMenuCardapio() {

    const [active, setActive ] = useState(false);

    useEffect(function() {
      function positionScroll(){
      if(window.scrollY > 250) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
      window.addEventListener('scroll', positionScroll)
    }, []);


  return (
    <>
        <NavMenu active={active}>
                <NavItem>
                    <NavLinks 
                        to="couvert"
                        smooth={true}
                        duration={2500}
                        spy={true}
                        exact= 'true'
                        offset={-95}

                    >Couvert</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="saladas"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Saladas</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="piraputanga"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Piraputanga</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="pintado"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Pintado</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="tilapia"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Tilápia</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="pacu"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Pacu</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="carne_bovina"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Carne Bovina</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="adicionais"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Adicionais</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="para_uma_pessoa"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Para uma Pessoa</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="porcoes"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Porções</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="sucos"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Sucos</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="coqueteis"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Coquetéis</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="drinks_s_alcool"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Drinks s/ álcool</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="batidas"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Batidas</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="doses"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Doses</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="cafés"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Cafés</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks 
                    to="sobremesas"
                    smooth={true}
                    duration={2500}
                    spy={true}
                    exact= 'true'
                    offset={-95}
                    
                    >Sobremesa</NavLinks>
                </NavItem>
        </NavMenu>
      
    </>
  )
}

export default NavMenuCardapio;
