import img01 from '../../images/safadinha.png';
import img02 from '../../images/02.jpg';
import img03 from '../../images/03.png';

export const items = [
    {
        image: img01,
        title: "Piraputanga Safadinha"
    },
    {
        image: img02,
        title: "Moqueca de Pintado"
    },
    {
        image: img03,
        title: ""
    }
    
    
];