import React from 'react'
import eua from '../../../images/eua.png';
import esp from '../../../images/esp.png';

import {
    ContentBody,
    WrapperName,
    TitlePage,
    WrapCards,
    WrapperSection,
    CardProd,
    WrapperInfos,
    WrapFoto,
    FotoItem,
    WrapperNomeDesc,
    WrapperDescEPrice,
    H2Nome,
    DescProdAlpha,
    WrapperDesc,
    DescProd,
    SpanLang,
    Icon,
    WrapperPrice,
    PriceH2,
    CardProdNoImg,
    NameNoImg,
    DescIng,
    DescEsp,
    WrapperPriceNoImg,
    PriceNoImg
} from './contentBodyCardapioElements';

const ContentBodyCardapio = () => {

    const dataCouvert = [
        {
            "foto": require("../../../images/cardapio/001.jpg"),
            "nome": "Peixe Caverna",
            "descriçãoPt": 'Deliciosos bolinhos de peixe com queijo muçarela acompanhados de exclusiva geleia de pimenta.',
            "descriçãoIng": 'Cheese-filled croquettes accompanied by pepper jam.',
            "descriçãoEsp": 'Croquetas de pescado qo rellenas con queso.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$26,00'
        },
        {
            "foto": require("../../../images/cardapio/002.jpg"),
            "nome": "Dadinho de Tapioca",
            "descriçãoPt": 'Farinha de tapioca, queijo parmesão, ovos e leite.',
            "descriçãoIng": 'Tapioca flour, parmesan cheese, eggs and milk.',
            "descriçãoEsp": 'Harina de tapioca, queso parmesano, huevos y leche.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$26,00'

        },
        {
            "foto": require("../../../images/cardapio/003.jpg"),
            "nome": "Bolinho Pantaneiro",
            "descriçãoPt": 'Arroz, carne de sol, queijo parmesão e cheiro verde.',
            "descriçãoIng": 'Rice, jerked beef, parmesan cheese, parsley and chives.',
            "descriçãoEsp": 'Arroz, carne desidratada, queso',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$26,00'

        },
    ]

    const dataSaladas = [
        {
            "foto": require("../../../images/cardapio/004.jpg"),
            "nome": "Salada Simples",
            "descriçãoPt": 'Alface, tomate e cebola.',
            "descriçãoIng": 'Lettuce, tomato and onion.',
            "descriçãoEsp": 'Lechuga, tomate y cebola.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$19,00'
        },
        {
            "foto": require("../../../images/cardapio/005.jpg"),
            "nome": "Salada Gaúcha",
            "descriçãoPt": 'Alface, tomate, bacon, palmito, batata palha, azeitonas e molho da casa.',
            "descriçãoIng": 'Lettuce, tomato, bacon, palm heart, shoestring potatoes, olives and house dressing.',
            "descriçãoEsp": 'Lechuga, tomate, bacon, palmito, patata paja, aceitunas y salsa de la casa.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$48,00'
        },
        {
            "foto": require("../../../images/cardapio/006.jpg"),
            "nome": "Salada Tropical",
            "descriçãoPt": 'Alface, tomate, palmito, cebola, azeitona, kani-kama e molho da casa.',
            "descriçãoIng": 'Lettuce, tomato, palm heart, onion, olives, kani-kama and house dressing.',
            "descriçãoEsp": 'Lechuga, tomate, palmito, cebolla, aceituna, kani-kama y salsa de la casa.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$48,00'
        },
        {
            "foto": require("../../../images/cardapio/007.jpg"),
            "nome": "Salada Individual",
            "descriçãoPt": 'Alface, tomate e cebola.',
            "descriçãoIng": 'Lettuce, tomato and onion.',
            "descriçãoEsp": 'Lechuga, tomate y cebolla.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$10,00'
        },
    ]

    const dataPiraputanga = [
        {
            "foto": require("../../../images/cardapio/008.jpg"),
            "nome": "Piraputanga Recatada",
            "descriçãoPt": 'Piraputanga frita servida inteira, acompanhado de arroz, batata frita e pirão.',
            "descriçãoIng": 'Breaded Piraputanga fish, served with rice, french fries and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Piraputanga rebozada, servida con arroz, papas fritas y pirão (pure de harina de yuca y pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$152,00'
        },
        {
            "foto": require("../../../images/cardapio/009.jpg"),
            "nome": "Piraputanga Grelhada",
            "descriçãoPt": 'Piraputanga grelhada, servida com arroz, batata e pirão.',
            "descriçãoIng": 'Grilled Piraputanga fish, served with rice, potato and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Piraputanga a la plancha, servida con arroz, papas y pirão (pure de harina de yuca y pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$152,00'
        },
    ]

    const dataPintado = [
        {
            "foto": require("../../../images/cardapio/010.jpg"),
            "nome": "Pintado ao Urucum",
            "descriçãoPt": 'File de Pintado a dorê coberto com molho especial de urucum, queijo gratinado, servido com arroz e farofa de banana.',
            "descriçãoIng": 'Pintado fish fillet covered with a Brazilian annatto sauce au gratin with cheese, served with rice, and banana crumbs. ',
            "descriçãoEsp": '/ Filet de Pintado encubierto con salsa de urucum, servido con arroz y farofa de plátano.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$149,00'
        },
        {
            "foto": require("../../../images/cardapio/011.jpg"),
            "nome": "Pintado na Telha ao Molho de Camarão",
            "descriçãoPt": 'Ensopado de Pintado na telha de barro com camarão, cebola, tomate, pimentão, leite de coco, azeite de dendê, creme de leite, arroz e farofa de banana.',
            "descriçãoIng": 'Pintado fish stew with shrimps sauce, onion, tomato, sweet pepper, coconut milk, dendê (palm) oil, heavy cream, served with rice.',
            "descriçãoEsp": 'Guisado de Pintado en la teja con gambas, cebolla, tomate, pimiento dulce, leche de coco, aceite de dendê (palma) y crema de leche, servido con arroz.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$188,00'
        },
        {
            "foto": require("../../../images/cardapio/012.jpg"),
            "nome": "Pintado à Parmegiana",
            "descriçãoPt": 'File de Pintado à milanesa, molho de tomate, queijo gratinado, servido com arroz e batata frita.',
            "descriçãoIng": 'Breaded fish with tomato sauce au gratin, served with rice and French fries.',
            "descriçãoEsp": 'Parmegiana de pescado con salsa de tomate, gratinado con queso, servido con arroz e papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$135,00'
        },
        {
            "foto": require("../../../images/cardapio/013.jpg"),
            "nome": "Pintado Grelhado com Alcaparras",
            "descriçãoPt": 'Servido com arroz, batata saute e farofa de banana.',
            "descriçãoIng": 'Grilled Pintado fish fillet with capers, served with rice, sauteed potatoes and banana crumbs.',
            "descriçãoEsp": 'filet de pescado a la plancha, servido con arroz, papas saute y farofa de plátano.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$145,00'
        },
        {
            "foto": require("../../../images/cardapio/014.jpg"),
            "nome": "Pintado à Milanesa",
            "descriçãoPt": 'Servido com arroz, batata frita e pirão.',
            "descriçãoIng": 'Breaded fish, served with rice, french fries and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Milanesa de pescado servido con arroz, papas fritas y pirão (pure de harina de yuca y pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$110,00'
        },
        {
            "foto": require("../../../images/cardapio/015.jpg"),
            "nome": "Pintado ao Imperador",
            "descriçãoPt": 'Cortes especiais de Pintado, grelhado com amêndoas levemente torradas e champignon na manteiga, servido com arroz e batata saute.',
            "descriçãoIng": 'Grilled fish fillet, lightly toasted almonds and mushrooms in butter, served with rice and sauteed potatoes.',
            "descriçãoEsp": 'Filet de pescado a la plancha con almendras tostadas y champifiones en mantequilla, servido con arroz y papas saute.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$168,00'
        },
        {
            "foto": require("../../../images/cardapio/016.jpg"),
            "nome": "Moqueca de Pintado",
            "descriçãoPt": 'Ensopado com tomate, cebola, pimentão, leite de coco, azeite de dendê, arroz e farofa de banana.',
            "descriçãoIng": 'Brazilian fish stew with onions, tomatoes, palm tree oil and coconut milk, served with rice and banana crumbs.',
            "descriçãoEsp": 'Guisado de pescado, cebolla, tomate, pimiento dulce, leche de coco, aceite de dendê (palma), servido con arroz y farofa de plátano.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$152,00'
        },
        
    ]

    const dataTilapia = [
        {
            "foto": require("../../../images/cardapio/017.jpg"),
            "nome": "Tilápia à Milanesa",
            "descriçãoPt": 'Filé de Tilápia à milanesa servido com arroz e batata frita.',
            "descriçãoIng": 'Breaded fish fillet, served with rice and French fries.',
            "descriçãoEsp": 'Filet de Tilápia rebozado, servido con arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$136,00'
        },
        {
            "foto": require("../../../images/cardapio/018.jpg"),
            "nome": "Tilápia Rincão",
            "descriçãoPt": 'File de Tilápia à milanesa, servido com arroz e pirão.',
            "descriçãoIng": 'Breaded fish fillet, served with rice and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Filet de Tilápia rebozado, servido con arroz y pirão (pure de harina de yuca y pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$136,00'
        },
        {
            "foto": require("../../../images/cardapio/019.jpg"),
            "nome": "Casal Bonito",
            "descriçãoPt": 'File de Tilápia à milanesa e costela de Pacu fritas, servido com arroz e pirão.',
            "descriçãoIng": 'Breaded Tilapia fish fillet and Pacu fish ribs, served with rice and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Filet de Tilápia (pescado) rebozado y costillas de Pacu (pescado), servido con arroz y pirão (pure de harina de yuca y pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$145,00'
        }
        
    ]

    const dataPacu = [
        {
            "foto": require("../../../images/cardapio/foto_em_breve.jpg"),
            "nome": "Pacu do Pescador",
            "descriçãoPt": 'Pacu grelhado com cebola e tomate picados, gratinado com queijo, servido com arroz, batata saute e farofa de banana.',
            "descriçãoIng": 'Grilled skin-on Pacu fish with chopped onion and tomato, covered with cheese au gratin, served with rice, sautéed potatoes and banana crumbs.',
            "descriçãoEsp": 'Banda de Pacu a la plancha con cebolia y tomate picados, cubierto con queso gratinado, servido con arroz, papas saute y farofa de plátano.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$143,00'
        },
        {
            "foto": require("../../../images/cardapio/021.jpg"),
            "nome": "Pacu Grelhado",
            "descriçãoPt": 'Servido com arroz, farofa de banana, legumes no vapor e pirão.',
            "descriçãoIng": 'Grilled Pocu fish, served with rice, banana crumbs, steamed vegetables and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Pacu a la plancha, servido con arroz, farofa de plátano, legumbres al vapor y pirão (pure de harina de yuca y pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$136,00'
        },
        {
            "foto": require("../../../images/cardapio/022.jpg"),
            "nome": "Pacu Frito",
            "descriçãoPt": 'Servido com arroz, batata frita, farofa de banana e pirão.',
            "descriçãoIng": 'Breaded fish ribs, served with rice, french fries, banana crumbs and pirão (pure of manioc flour and fish).',
            "descriçãoEsp": 'Costillas de Pacu rebozada, servida con arroz, papas fritas, farofa de plátano y pirão (pure de harina de yuca y pescado)',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$107,00'
        },
        
    ]

    const dataCarneBovina = [
        {
            "foto": require("../../../images/cardapio/023.jpg"),
            "nome": "Bife Ancho Mimoso",
            "descriçãoPt": 'Grelhado, servido com arroz, batata frita e farofa de alho.',
            "descriçãoIng": 'Grilled sirloin steak served with rice, French fries and garlic crumbs.',
            "descriçãoEsp": 'Bife de chorizo a la plancha servido con arroz, papas fritas y farofa de ajo.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$92,00'
        },
        {
            "foto": require("../../../images/cardapio/024.jpg"),
            "nome": "Bife Ancho Cowboy",
            "descriçãoPt": 'Grelhado e acebolado, servido com 2 ovos fritos, arroz, batata frita e farofa de alho.',
            "descriçãoIng": 'Grilled sirloin steak with saute onions, served with 2 sunny side eggs, rice, French fries and garlic crumbs.',
            "descriçãoEsp": 'Croquetas de pescado qo rellenas con queso.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$99,00'
        },
        {
            "foto": require("../../../images/cardapio/025.jpg"),
            "nome": "Família Pintada",
            "descriçãoPt": 'Bife ancho grelhado e acebolado, servido com 3 ovos fritos, arroz e batata frita.',
            "descriçãoIng": 'Grilled sirloin steak with saute onions, served with 3 sunny side eggs, rice and French fries.',
            "descriçãoEsp": 'Bife de chorizo a la plancha con cebola, servido con 3 huevos fritos, arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$144,00'
        },
        {
            "foto": require("../../../images/cardapio/026.jpg"),
            "nome": "File Mignon ao Molho Miellé",
            "descriçãoPt": 'Medalhas de mignon grelhado, servido com molho agridoce de mel, champignon e especiarias. Acompanha arroz e batata frita.',
            "descriçãoIng": 'Grilled tenderloin medallion served with bittersweet sauce of honey mushroom and spices, accompanies rice and French fries.',
            "descriçãoEsp": 'Medallas de lomo a la plancha, servido con salsa agridulce de miel, champifion y especiarias. Acompaiia arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$135,00'
        },
        {
            "foto": require("../../../images/cardapio/027.jpg"),
            "nome": "Filé Mignon à Parmegiana",
            "descriçãoPt": 'Filé mignon à milanesa, molho de tomate, queijo gratinado, servido com arroz e batata frita.',
            "descriçãoIng": 'Breaded tenderloin fillet, covered by tomato sauce and cheese au gratin, served with rice and French fries.',
            "descriçãoEsp": 'Parmegiana de lomo con salsa de tomate, queso gratinado, servido con arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$130,00'
        },
        {
            "foto": require("../../../images/cardapio/028.jpg"),
            "nome": "Filé Mignon Grelhado",
            "descriçãoPt": 'Medalhas de mignon grelhado, arroz e batata dourada.',
            "descriçãoIng": 'Grilled tenderloin medallion, served with rice and golden potatoes.',
            "descriçãoEsp": 'Medallas de lomo a la plancha, servido con arroz y papas doradas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$120,00'
        },
        {
            "foto": require("../../../images/cardapio/029.jpg"),
            "nome": "Filé Mignon Grelhado ao Molho Provolone",
            "descriçãoPt": 'Medalhas de mignon grelhado, queijo provolone, servido com arroz e batata frita.',
            "descriçãoIng": 'Grilled tenderloin medallion, provolone cheese, served with rice and French fries.',
            "descriçãoEsp": 'Medallas de lomo a la plancha, queso provolone, servido con arroz y papas frita.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$146,00'
        },
        
    ]

    const dataAdicionais = [
        {
            "nome": "Arroz",
            "descIng": 'Rice',
            "price": 'R$9,00'
        },
        {
            "nome": "Pirão",
            "descIng": 'Pure of manioc flour and fish',
            "descEsp": 'Pure de harina de yuca y pescado',
            "price": 'R$13,00'
        },
        {
            "nome": "Batata Saute",
            "descIng": 'Sauteed potatoes',
            "descEsp": 'Papas soute',
            "price": 'R$16,00'
        },
        {
            "nome": "Feijão",
            "descIng": 'Brown beans',
            "descEsp": 'Frijoles',
            "price": 'R$9,00'
        },
        {
            "nome": "Legumes no Vapor",
            "descIng": 'Steamed vegetables',
            "descEsp": 'Legumbres al vapor',
            "price": 'R$21,00'
        },
        {
            "nome": "Ovo Frito",
            "descIng": 'Sunny side egg',
            "descEsp": 'Huevo frito',
            "price": 'R$4,50'
        },
        {
            "nome": "Farofa de Alho",
            "descIng": 'Garlic crumbs',
            "descEsp": 'Farofa de ajo',
            "price": 'R$9,00'
        },
        {
            "nome": "Farofa de Banana",
            "descIng": 'Banana crumbs',
            "descEsp": 'Farofa de plátano',
            "price": 'R$9,00'
        },
        {
            "nome": "Embalagem",
            "descIng": 'Conteiner to take away',
            "descEsp": 'Embalaje',
            "price": 'R$4,00'
        },
    ]

    const dataParaUmaPessoa = [
        {
            "foto": require("../../../images/cardapio/030_foto_em_breve.jpg"),
            "nome": "Pintado à Milanesa",
            "descriçãoPt": 'Servido com arroz e batata frita.',
            "descriçãoIng": 'Breaded Pintado Fish Fillet, served with rice and french fries',
            "descriçãoEsp": 'Milanesa de Pintado (pescado), servido con arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$62,00'
        },
        {
            "foto": require("../../../images/cardapio/031.jpg"),
            "nome": "Pintado ao Urucum",
            "descriçãoPt": 'File de Pintado a dorê coberto com molho especial de urucum, queijo gratinado, servido com arroz, batata dourada.',
            "descriçãoIng": 'Pintado fish fillet cover with a Brazilian annaito sauce au gratin with cheese, served with rice and golden potato.',
            "descriçãoEsp": 'Filet de Pintado (pescado) encubierto con salsa de urucu, servido con arroz y patata de oro.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$77,00'
        },
        {
            "foto": require("../../../images/cardapio/032.jpg"),
            "nome": "Frango Grelhado",
            "descriçãoPt": 'Filé de frango grelhado, servido com arroz e batata frita.',
            "descriçãoIng": 'Grilled fillet of chicken breast, served with rice and French fries.',
            "descriçãoEsp": 'Filet de pechuga de pollo a ta plancha, servido con arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$48,00'
        },
        {
            "foto": require("../../../images/cardapio/033_foto_em_breve.jpg"),
            "nome": "Bife ancho Mimoso",
            "descriçãoPt": 'Grelhado, servido com arroz, batata frita e farofa de alho.',
            "descriçãoIng": 'Grilled sirloin steak, served with rice, French fries and garlic crumbs.',
            "descriçãoEsp": 'Bife de chorizo a la plancha, servido con arroz, papas fritas y farofa de ajo.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$52,00'
        },
        {
            "foto": require("../../../images/cardapio/034_foto_em_breve.jpg"),
            "nome": "Tilápia à Milanesa",
            "descriçãoPt": 'Filé de Tilápia à milanesa, servido com arroz e batata frita.',
            "descriçãoIng": 'Breaded Tilápia fish fillet, served with rice and French fries.',
            "descriçãoEsp": 'Filet de Tilápia (pescado) rebozado, servido con arroz y papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$55,00'
        },
        {
            "foto": require("../../../images/cardapio/035.jpg"),
            "nome": "Pintado Fit",
            "descriçãoPt": 'Cortes especiais de pintado, grelhado com amêndoas levemente torradas e champignon no azeite, servido com batata doce.',
            "descriçãoIng": 'Grilled Pintado fish with lightly toasted almonds and mushrooms in butter, served with sweet potatoes.',
            "descriçãoEsp": 'Pintado (pescado) a la plancha, con almendras ligeramente tostadas y champifiones en mantequilla,servido con papas dulce.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$86,00'
        },
        {
            "foto": require("../../../images/cardapio/036.jpg"),
            "nome": "Moqueca Vegana",
            "descriçãoPt": 'Legumes salteados em azeite de oliva com molho de tomate, cebola, pimentão, leite de coco e banana da terra, servido com arroz.',
            "descriçãoIng": 'Sautéed vegetables with olive oil, with tomatoes sauce, onion, sweet pepper, coconut milk and banana, served with rice.',
            "descriçãoEsp": 'Legumbres salteados al aceite de olive, con salsa de tomate, cebolla, pimiento dulce, leche de coco y plátano, servido con arroz.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$39,00'
        },
        {
            "foto": require("../../../images/cardapio/037_foto_em_breve.jpg"),
            "nome": "Vegetariano",
            "descriçãoPt": 'Omelete, servido com arroz, alface, tomate e cebola.',
            "descriçãoIng": 'Omeleite, served with rice, lettuce, tomatoes and onion.',
            "descriçãoEsp": ' Tortilla, servida con arroz, lechuga, tomate ycebolla.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$32,00'
        },
        {
            "foto": require("../../../images/cardapio/038.jpg"),
            "nome": "Legumes ao Molho Provolone",
            "descriçãoPt": 'Legumes na manteiga com molho de queijo Provolone.',
            "descriçãoIng": 'Vegetables in butter with Provolone cheese sauce.',
            "descriçãoEsp": 'Legumbres en mantequilia con salsa de queso Provolone.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$39,00'
        },
        {
            "foto": require("../../../images/cardapio/039.jpg"),
            "nome": "Kids Carne",
            "descriçãoPt": 'Iscas de filé mignon grelhada, servido com arroz, batata frita e feijão.',
            "descriçãoIng": 'Grilled tenderloin stripes, served with rice, French fries and brown beans.',
            "descriçãoEsp": 'Tiritas de lomo de ternera a la plancha, servida con arroz, papas fritas y frijoles.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$31,50'
        },
        {
            "foto": require("../../../images/cardapio/041.jpg"),
            "nome": "Kids Peixe",
            "descriçãoPt": 'Iscas de filé de tilápia à dorê, servido com arroz, batata frita e feijão.',
            "descriçãoIng": 'Breaded fish stripes, served with rice, French fries and brown beans.',
            "descriçãoEsp": 'Tiritas de Tilápia rebozado, servida con arroz, papas fritas y frijoles.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$31,50'
        },
        
    ]

    const dataPorcoes = [
        {
            "foto": require("../../../images/cardapio/042.jpg"),
            "nome": "Piraputanga Safadinha (Peixe inteiro)",
            "descriçãoPt": '',
            "descriçãoIng": 'Breaded Piraputanga fish (the whole fish).',
            "descriçãoEsp": 'Piraputanga (pescado) frito entero.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$115,00'
        },
        {
            "foto": require("../../../images/cardapio/043_foto_em_breve.jpg"),
            "nome": "Isca de Tilápia",
            "descriçãoPt": '',
            "descriçãoIng": 'Breaded Tilapia fish strips.',
            "descriçãoEsp": 'Tiritas de filet de Tilápia (pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$75,00'
        },
        {
            "foto": require("../../../images/cardapio/044_foto_em_breve.jpg"),
            "nome": "Isca de Pintado",
            "descriçãoPt": '',
            "descriçãoIng": 'Breaded Pintado fish strips.',
            "descriçãoEsp": 'Tiritas de Pintado (pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$80,00'
        },
        {
            "foto": require("../../../images/cardapio/045.jpg"),
            "nome": "Batata Frita",
            "descriçãoPt": '',
            "descriçãoIng": 'French fries.',
            "descriçãoEsp": 'Papas fritas.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$33,00'
        },
        {
            "foto": require("../../../images/cardapio/046.jpg"),
            "nome": "Contra Filé Acebolado",
            "descriçãoPt": '',
            "descriçãoIng": 'Grilled sirloin steak with sautéed onions.',
            "descriçãoEsp": 'Bife de chorizo a la plancha con cebola.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$75,00'
        },
        {
            "foto": require("../../../images/cardapio/047.jpg"),
            "nome": "Pacu na Trilha",
            "descriçãoPt": '',
            "descriçãoIng": 'Breaded Pacu fish ribs.',
            "descriçãoEsp": 'Costilla de Pacu (pescado).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$77,00'
        },
        {
            "foto": require("../../../images/cardapio/048.jpg"),
            "nome": "Jacaré Aperitivo (Dorê ou Grelhado)",
            "descriçãoPt": '(+ - 100g)',
            "descriçãoIng": 'Caiman strips (Breaded or Grilled).',
            "descriçãoEsp": 'Tiritas de caimán (Rebozado o a la plancha).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$45,00'
        },
        {
            "foto": require("../../../images/cardapio/049_foto_em_breve.jpg"),
            "nome": "Jacaré Porção (Dorê ou Grelhado)",
            "descriçãoPt": '(+ - 300g)',
            "descriçãoIng": 'Caiman strips (Breaded or Grilled).',
            "descriçãoEsp": 'Tiritas de caimán (Rebozado o a la plancha).',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$135,00'
        },
        {
            "foto": require("../../../images/cardapio/050.jpg"),
            "nome": "Mandioca Frita",
            "descriçãoPt": '',
            "descriçãoIng": 'Sticks of fried manioc.',
            "descriçãoEsp": 'Yuca frita.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$33,00'
        },
        {
            "foto": require("../../../images/cardapio/051_foto_em_breve.jpg"),
            "nome": "Filé Mignon Acebolado",
            "descriçãoPt": '',
            "descriçãoIng": 'Grilled tenderloin with sautéed onions.',
            "descriçãoEsp": 'Lomo de ternera a la plancha con cebolla.',
            
            "icon": require("../../../images/cardapio/2p.png"),
            "price": 'R$2,00'
        },
        
    ]

    const dataBebidas = [
        {
            "nome": "Becks 600ml",
            "descIng": 'Cerveja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$21,00'
        },
        {
            "nome": "Stella 600ml",
            "descIng": 'Cerveja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$21,00'
        },
        {
            "nome": "Spaten 600ml",
            "descIng": 'Cerveja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$18,00'
        },
        {
            "nome": "Original 600ml",
            "descIng": 'Cerveja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$21,00'
        },
        {
            "nome": "Brahma 600ml",
            "descIng": 'Cerveja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$21,00'
        },
        {
            "nome": "Corona Long Neck 330ml",
            "descIng": 'Cerveja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$14,00'
        },
        {
            "nome": "Stella Long Neck 600ml",
            "descIng": 'Cervaja',
            "descEsp": 'Beer | Cerveza',
            "price": 'R$13,00'
        },
        {
            "nome": "Budweiser Long Neck 330ml e Bud Zero",
            "descIng": 'Long neck and alcohol free beer',
            "descEsp": 'Cerveza long neck y sin alcohol',
            "price": 'R$10,00'
        },
        {
            "nome": "Energético",
            "descIng": 'Energy Drink',
            "descEsp": 'Energético',
            "price": 'R$18,00'
        },
        {
            "nome": "Refrigerante lata 350ml /KS 290ml - R$ 7,50",
            "descIng": 'Soft Drink',
            "descEsp": 'Gaseosa',
            "price": 'R$7,50'
        },
        {
            "nome": "Água Tônica / Água Tônica zero",
            "descIng": 'Tonic water / Tonic Zero',
            "descEsp": '',
            "price": 'R$8,50'
        },
        {
            "nome": "Água Mineral com Gás",
            "descIng": 'Sparkling water',
            "descEsp": 'Con gás',
            "price": 'R$5,00'
        },
        {
            "nome": "Água Mineral sem Gás",
            "descIng": 'Still water',
            "descEsp": 'Sin gás',
            "price": 'R$5,00'
        },
        
    ]

    return (
    <>
    <ContentBody>
        <WrapperName>
        

        <WrapperSection id='couvert'>
            <TitlePage>Varandas</TitlePage>
            <WrapCards>
                {dataCouvert.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='saladas'>
            <TitlePage>Saladas</TitlePage>
            <WrapCards>
                {dataSaladas.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='piraputanga'>
            <TitlePage>Piraputanga</TitlePage>
            <WrapCards>
                {dataPiraputanga.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='pintado'>
            <TitlePage>Pintado</TitlePage>
            <WrapCards>
                {dataPintado.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='tilapia'>
            <TitlePage>Tilápia</TitlePage>
            <WrapCards>
                {dataTilapia.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='pacu'>
            <TitlePage>Pacu</TitlePage>
            <WrapCards>
                {dataPacu.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
            </WrapCards>
        </WrapperSection>

        <WrapperSection id='carne_bovina'>
            <TitlePage>Carne Bovina</TitlePage>
            <WrapCards>
                {dataCarneBovina.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='adicionais'>
            <TitlePage>Adicionais</TitlePage>
            <WrapCards>
            {dataAdicionais.map((item, index) => 
                    <CardProdNoImg key={index}>
                        <WrapperInfos>
                            <NameNoImg>{item.nome}</NameNoImg>
                            <DescIng>{item.descIng}</DescIng>
                            <DescEsp>{item.descEsp}</DescEsp>
                        </WrapperInfos>
                        <WrapperPriceNoImg>
                            <PriceNoImg>{item.price}</PriceNoImg>
                        </WrapperPriceNoImg>
                    </CardProdNoImg>
                )}

            </WrapCards>

        </WrapperSection>

        <WrapperSection id='para_uma_pessoa'>
            <TitlePage>Para uma Pessoa</TitlePage>
            <WrapCards>
                {dataParaUmaPessoa.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    <DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='porcoes'>
            <TitlePage>Porções</TitlePage>
            <WrapCards>
                {dataPorcoes.map((item, index) => 
                    <CardProd key={index}>
                        <WrapperInfos>

                            <WrapFoto>
                                <FotoItem src={item.foto} />
                            </WrapFoto>

                            <WrapperDescEPrice>
                                <WrapperNomeDesc>
                                    <H2Nome>{item.nome}</H2Nome>
                                    {item.descriçãoPt !== '' && (<DescProdAlpha>{item.descriçãoPt}</DescProdAlpha>)}

                                    <WrapperDesc>
                                        <SpanLang src={eua} />
                                        <DescProd>{item.descriçãoIng}</DescProd>
                                    </WrapperDesc>

                                    <WrapperDesc>
                                        <SpanLang src={esp} />
                                        <DescProd>{item.descriçãoEsp}</DescProd>
                                    </WrapperDesc>

                                    
                                </WrapperNomeDesc>

                            <WrapperPrice>
                                <PriceH2>{item.price}</PriceH2>
                            </WrapperPrice>

                            </WrapperDescEPrice>

                        </WrapperInfos>
                        {/* <Icon src={item.icon}/> */}

                    </CardProd>
                )}
                </WrapCards>
        </WrapperSection>

        <WrapperSection id='bebidas'>
            <TitlePage>Bebidas</TitlePage>
            <WrapCards>
            {dataBebidas.map((item, index) => 
                    <CardProdNoImg key={index}>
                        <WrapperInfos>
                            <NameNoImg>{item.nome}</NameNoImg>
                            <DescIng>{item.descIng}</DescIng>
                            <DescEsp>{item.descEsp}</DescEsp>
                        </WrapperInfos>
                        <WrapperPriceNoImg>
                            <PriceNoImg>{item.price}</PriceNoImg>
                        </WrapperPriceNoImg>
                    </CardProdNoImg>
                )}


            </WrapCards>

        </WrapperSection>



        </WrapperName>
    </ContentBody>
    </>
  )
}

export default ContentBodyCardapio;

