import React, {useRef, useEffect} from 'react'
import Img from '../../images/history_photo_01.png';
import { motion, useInView, useAnimation } from "framer-motion";

import {
    HistoryWrapper,
    TitleHistory,
    HistoryImg1,
    Wrapper1,
    TitleSeparador,
    Parag,
    Wrapper2,
    Parag2
} from './HistoryElements';


const HistorySection = () => {
    const ref = useRef(null);
    const mainControls = useAnimation();

    const isInView = useInView(ref, {once: false});


    useEffect(() => {
        if(isInView){
            mainControls.start("visible");
        }
    }, [isInView]);


  return (
    <>
        <HistoryWrapper ref={ref} id='varandas'>
        <motion.div 
            variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
            }}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            initial= "hidden"
            animate= {mainControls}
            transition={{ duration: .5, delay: .5 }}
        >
            <TitleHistory>Nossa História</TitleHistory>
            <TitleSeparador>.</TitleSeparador>
            <Wrapper1>
                <HistoryImg1 src={Img}/>
                <Parag>Em 2023 O Varandas Restaurante completa 13 anos desde sua fundação. Aqui sempre prezamos pela boa culinária e ambiente. Então em 2010 surgiu o restaurante Aquário.</Parag>
            </Wrapper1>

            <Wrapper2>
                <HistoryImg1 src={Img}/>
                <Parag2>Em 2023 O Varandas Restaurante completa 13 anos desde sua fundação. Aqui sempre prezamos pela boa culinária e ambiente. Então em 2010 surgiu o restaurante Aquário.</Parag2>
            </Wrapper2>

            <Wrapper1>
                <HistoryImg1 src={Img}/>
                <Parag>Em 2023 O Varandas Restaurante completa 13 anos desde sua fundação. Aqui sempre prezamos pela boa culinária e ambiente. Então em 2010 surgiu o restaurante Aquário.</Parag>
            </Wrapper1>
        </ motion.div>
        </HistoryWrapper>
      
    </>
  )
}


export default HistorySection

