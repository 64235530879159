import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

import {  
    Button,
    ArrowTop
} from './ButonStyled';

const options = {
  spy: true,
  duration: 2500,
  smooth: true,
};

const scrollToTop = () => {
  scroll.scrollToTop(options);
}

const BottomScrollToTop = () => {
  return (
    <>
    <Button onClick={scrollToTop}>
      <ArrowTop />
    </Button>
      
    </>
  )
}


export default BottomScrollToTop;
