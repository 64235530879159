import styled from 'styled-components';


export const WrapperCardapio = styled.div`
    display: flex;
    align-items: center;
`
export const FotoCardapio = styled.div`
    display: flex;


    @media screen and (max-width: 1100px) {
        width: 100%;

    }
`
export const PhotoCardapio = styled.img`
    width: 1000px;

    @media screen and (max-width: 1000px) {
        opacity: 0.7;
    }
`
export const InfosCardapio = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 950px) {
        position: absolute;
    }
`
export const TitleCardapio = styled.h3`
    font-size: 60px;
    text-transform: uppercase;
    text-align: center;
    max-width: 600px;
    margin-bottom: 50px;

    @media screen and (max-width: 500px) {
        font-size: 40px;
    }
`
