import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";


import img from '../../images/peixe.png';
import img2 from '../../images/carne.png';
import img3 from '../../images/petisco.png';

import { 
    WrapperCulinaria,
    TitleCulinaria,
    WrapperItems,
    ItemCulinaria,
    Ing,
    TitleItem,
    TextItem,
    TitleSeparador
 } from './CulinariaElements';

const CulinariaSection = () => {
    const ref = useRef(null);
    const mainControls = useAnimation();

    const isInView = useInView(ref, {once: false});


    useEffect(() => {
        if(isInView){
            mainControls.start("visible");
        }
    }, [isInView]);



  return (
    <>

        <WrapperCulinaria ref={ref} id='culinaria'>

        <motion.div 
            variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
            }}
            style={{
                display: "flex",
                width: "100%",
                border: 0,
                flexDirection: "column",
                alignItems: "center",
            }}
            initial= "hidden"
            animate= {mainControls}
            transition={{ duration: .5, delay: .5}}
        >
            <TitleCulinaria>Culinária</TitleCulinaria>
            <TitleSeparador>.</TitleSeparador>

            <WrapperItems>
                <ItemCulinaria>
                    <Ing src={img}/>
                    <TitleItem>Peixes</TitleItem>
                    <TextItem>Uma variedade de peixes com receitas originais em um cardápio diverso </TextItem>
                </ItemCulinaria>

                <ItemCulinaria>
                    <Ing src={img2}/>
                    <TitleItem>Carnes Vermelhas</TitleItem>
                    <TextItem>Uma variedade de peixes com receitas originais em um cardápio diverso </TextItem>
                </ItemCulinaria>

                <ItemCulinaria>
                    <Ing src={img3}/>
                    <TitleItem>Petiscos</TitleItem>
                    <TextItem>Uma variedade de peixes com receitas originais em um cardápio diverso </TextItem>
                </ItemCulinaria>
            </WrapperItems>
            </motion.div>

        </WrapperCulinaria>
      
    </>
  )
}

export default CulinariaSection
