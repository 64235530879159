import { useState, React, useRef, useEffect } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";



import {
  Carousel,
    WrapperCard,
    CarouselCard,
    ImgSlider,
    Overlay,
    ArrowRight,
    Button
} from './SliderElements';


const Slider = ({images}) => {

  const [current, setCurrent] = useState(0);

  const slideRight = () => {
    setCurrent(current === images.length -1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  }

  const ref = useRef(null);
    const mainControls = useAnimation();

    const isInView = useInView(ref, {once: false});


    useEffect(() => {
        if(isInView){
            mainControls.start("visible");
        }
    }, [isInView]);


  return (
    <Carousel id='fotos' className='carousel'>

      <motion.div 
            variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
            }}
            style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
            }}
            initial= "hidden"
            animate= {mainControls}
            transition={{ duration: .5, delay: .5}}
        >
        <WrapperCard ref={ref} className='carousel_wrapper'>
            {images.map((image, index) => {
              return(
              <CarouselCard key={index} className={index === current 
              ? "carousel_card_active" 
              : "carousel_card"
              }
              >
                <ImgSlider className='card_img' src={image.image} />
                <Overlay className='card_overlay'>
                  <h2 className='card_title'>
                    {image.title}
                  </h2>

                </Overlay>
                <Button className='arrowRight' onClick={slideRight}>{<ArrowRight />}</Button>
                <Button className='arrowLeft' onClick={slideLeft}>{<ArrowRight />}</Button>
              </CarouselCard>
              )
              

            })}

        </WrapperCard> 
        </motion.div>
    </Carousel>

  )
}

export default Slider
