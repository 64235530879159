import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";


import Img1 from '../../images/galeria_foto_01b.png';
import Img2 from '../../images/galeria_foto_02b.png';
import Img3 from '../../images/galeria_foto_03b.png';
import Img4 from '../../images/galeria_foto_04b.png';
import Img5 from '../../images/galeria_foto_05b.png';

import { 
    FullWrapper,
    WrapperAllPhotos,
    FirstItem,
    Img01,
    SecondItem,
    Img02,
    Img03,
    FinalItem,
    Img04,
    Img05,
    WrapperAlpha,
    WrapperTitle,
    TitleGalery,
    TitleSeparador
 } from './GaleryElements';

const GalerySection = () => {
    const ref = useRef(null);
    const mainControls = useAnimation();

    const isInView = useInView(ref, {once: false});


    useEffect(() => {
        if(isInView){
            mainControls.start("visible");
        }
    }, [isInView]);




  return (
    <>
        <FullWrapper ref={ref} id='ambiente'>
        
            <WrapperTitle>
            <motion.div 
            variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 }
            }}
            style={{
                display: "flex",
                width: "100%",
                border: 0,
                flexDirection: "column",
                alignItems: "center",
            }}
            initial= "hidden"
            animate= {mainControls}
            transition={{ duration: .5}}
        >
                <TitleGalery>Ambiente</TitleGalery>
                <TitleSeparador>.</TitleSeparador>
        </motion.div>
            </WrapperTitle>
        
            <WrapperAllPhotos>
                <FirstItem>
                    <Img01 src={Img1} />
                </FirstItem>

                <WrapperAlpha>
                    <SecondItem>
                        <Img02 src={Img2} />
                        <Img03 src={Img3} />
                    </SecondItem>

                    <FinalItem>
                        <Img04 src={Img4} />
                        <Img05 src={Img5} />
                    </FinalItem>
                </WrapperAlpha>
            </WrapperAllPhotos>
        </FullWrapper>

      
    </>
  )
}

export default GalerySection;
