import styled from 'styled-components';


export const WrapperCulinaria = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #964F39;
    overflow-y: hidden;
    // border-bottom: 27px solid #FCC089;
`
export const TitleCulinaria = styled.h2`
    font-size: 40px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 40px;

`

export const TitleSeparador = styled.div`
    width: 40%;
    height: 15px;
    background: white;
    margin-bottom: 20px;
    overflow-y: hidden;

`


export const WrapperItems = styled.div`
    display: flex;
    padding: 30px 0 100px 0;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 100%;

    }
`
export const ItemCulinaria = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    margin: 35px;


`
export const Ing = styled.img`
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
`
export const TitleItem = styled.h3`
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;


`
export const TextItem = styled.p`
    color: white;
    text-align: center;
    font-size: 24px;

`