import styled from 'styled-components';
import {MdArrowForwardIos} from 'react-icons/md';


export const Carousel = styled.div`
    display: flex;
    height: 500px;
    width: 100%;
    overflow-y: hidden;
    background: #FF9746;
    align-self: center;
    justify-content: center;
`
    
    
export const WrapperCard = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

export const CarouselCard = styled.div`
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
    transition: 0.5s ease-in-out;
    pointer-events: none;
    transform: scale(.7);
    opacity: 0;


    &.carousel_card_active {
        opacity: 1;
        transform: scale(.8);
        pointer-events: visible;x
        background: black;
    }

`

export const ImgSlider = styled.img`
    width: 100%;
    object-fit: cover;
`

export const Overlay = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.29);
    padding: 40px 30px;
    align-items: flex-end;
    color: white;
    font-size: 26px;
`


export const ArrowRight = styled(MdArrowForwardIos)`
    margin-left: 3px;
    font-size: 30px;
`


export const Button = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(0, -50%);
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;


    &.arrowRight {
        right: 30px;
    }

    &.arrowLeft {
        left: 30px;
    }
`