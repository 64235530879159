import styled from 'styled-components';
export const HistoryWrapper = styled.div`
    display: flex;
    padding: 60px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #964F39;
    // border-top: solid 27px #FCC089;
    border-bottom: solid 27px #FCC089;
    overflow-y: hidden;

`

export const TitleHistory = styled.h2`
    display: flex;
    font-size: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    align-self: center;
    justify-content: center;
`

export const TitleSeparador = styled.div`
    display: flex;
    width: 40%;
    height: 15px;
    background: white;
    margin-bottom: 60px;
    overflow-y: hidden;

`

export const Wrapper1 = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    backgorund: yellow;
    padding: 20px;
    margin-bottom: 100px;


    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 60px;

    }
`

export const HistoryImg1 = styled.img`
    display: flex;
    width: 500px;
    margin: 0 40px 40px 40px;

    @media screen and (max-width: 768px) {
        width: 350px;
    }

`

export const Parag = styled.p`
    color: white;
    font-size: 32px;
    max-width: 800px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 20px;
        max-width: 400px;

    }
`


export const Wrapper2 = styled.div`
    display: flex;
    justify-content: center;
    backgorund: yellow;
    padding: 20px;
    align-items: center;
    margin-bottom: 100px;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        margin-bottom: 60px;

    }

`

export const Parag2 = styled.p`
    color: white;
    font-size: 32px;
    max-width: 800px;
    text-align: right;

    @media screen and (max-width: 768px) {
        font-size: 20px;
        max-width: 400px;
        text-align: center;
    }
`