import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideRedesWrap,
    SidebarRoute

} from './SidebarElements';
import { FaFacebook, FaInstagram } from 'react-icons/fa';




function Sidebar({ isOpen, toggle }) {
    
    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggleHome}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>

            <SidebarMenu>
                <SidebarLink 
                    to="varandas"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                
                    onClick={toggle}>
                    Varandas
                </SidebarLink>
                <SidebarLink 
                    to="ambiente"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    onClick={toggle}>
                    Ambiente
                </SidebarLink> 
                <SidebarLink 
                    to="culinaria"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    onClick={toggle}>
                    Culinária
                </SidebarLink>
                <SidebarLink 
                    to="fotos"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    onClick={toggle}>
                    Fotos
                </SidebarLink>
                <SidebarLink 
                    to="cardapio"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    exact= 'true'
                    offset={-80}
                    onClick={toggle}>
                    Cardápio
                </SidebarLink>
            </SidebarMenu>

            <SideRedesWrap>
                <SidebarRoute target="blank" to="https://www.facebook.com/varandasbonitoms/"><FaFacebook /></SidebarRoute>
                <SidebarRoute target="blank" to="https://www.instagram.com/varandasbonitoms"><FaInstagram /></SidebarRoute>
            </SideRedesWrap>

            
        </SidebarWrapper>

      
    </SidebarContainer>
  )
}

export default Sidebar;
