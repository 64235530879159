import React from 'react'
import { Button } from '../ButtonElements';
import { useNavigate } from 'react-router-dom';

import photo from '../../images/photoCardapio.png';

import { 
    WrapperCardapio,
    FotoCardapio,
    PhotoCardapio,
    InfosCardapio,
    TitleCardapio,
 } from './CardapioElements';


const CardapioSection = () => {
    let nav = useNavigate();
  
  
    return (
    
    <>
        <WrapperCardapio id='cardapio'>
            <FotoCardapio>
                <PhotoCardapio src={photo} />
            </FotoCardapio>

            <InfosCardapio>
                <TitleCardapio>Acesse aqui nosso Cardápio</TitleCardapio>
                <Button onClick={() => {nav("/cardapio")}}>Conhecer</Button>
            </InfosCardapio>
        </WrapperCardapio>
      
    </>
  )
}

export default CardapioSection
