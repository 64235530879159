import './App.css';
import Home from './pages';
import Cardapio from './pages/cardapio'
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></ Route>
        <Route path='/cardapio' element={<Cardapio />}></ Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
